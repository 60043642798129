import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const BlueBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonBlue};
  position: absolute;
  bottom: 815px;
  right: 43%;
  width: 71px;
  height: 71px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 800px;
    right: 88%;
  }
`;

const YellowBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonYellow};
  position: absolute;
  bottom: 606px;
  right: 2%;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 606px;
    right: -4%;
  }
`;

const LightOrangeBubble = styled(Box)`
  background-color: #ffefe4;
  position: absolute;
  bottom: 145px;
  right: 31%;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 145px;
    right: 60%;
  }
`;

const CultureBubbles = () => (
  <Box>
    <BlueBubble />
    <YellowBubble />
    <LightOrangeBubble />
  </Box>
);

export default CultureBubbles;
