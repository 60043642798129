import React from 'react';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import AboutHero from '../sections/about/AboutHero';
import Transparency from '../sections/about/Transparency';
import Team from '../sections/about/Team';
import TechnicalInterviewers from '../sections/about/TechnicalInterviewers';
import ImpactInitiatives from '../sections/about/ImpactInitiatives';
import Culture from '../sections/about/Culture';

const AboutUsPage = () => {
  return (
    <>
      <MetaData
        title='About Us'
        description='Nexton connects technical talent with purposeful startups around the world.'
      />
      <Layout selected='about' layoutTheme='blue'>
        <Box className='full-width hero' bg='nextonBlue'>
          <AboutHero />
        </Box>

        <Transparency />
        <Team />
        <TechnicalInterviewers />
        <ImpactInitiatives />
        <Culture />
      </Layout>
    </>
  );
};

export default AboutUsPage;
