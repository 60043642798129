import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const BlueBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonBlue};
  position: absolute;
  bottom: 360px;
  right: 7%;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  z-index: -1;
`;

const YellowBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonYellow};
  position: absolute;
  bottom: 235px;
  right: 41%;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 73px;
    right: 87%;
  }
`;

const TransparencyBubbles = () => (
  <Box>
    <BlueBubble />
    <YellowBubble />
  </Box>
);

export default TransparencyBubbles;
