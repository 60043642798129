import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import TransparencyBubbles from '../../components/about/TransparencyBubbles';
import transparency from '../../../assets/images/about/transparency.png';

const Transparency = () => (
  <Box py={['75px', '75px', '100px']} style={{ position: 'relative' }}>
    <Flex flexWrap='wrap' alignItems='flex-start'>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <Text fontSize={['45px', '70px']} color='nextonBlue' my='15px' as='h2' fontWeight='400'>
          Transparency
        </Text>
        <Text fontSize={['18px', '20px']} lineHeight='1.6' mb='50px'>
          Our process is 100% transparent. We set the right expectations for both companies and candidates from the get
          go. This visibility from the start helps invest just the right amount of time in the recruiting process.
        </Text>
      </Box>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} textAlign='center'>
        <Image src={transparency} alt='Transparency Image' />
      </Box>
    </Flex>
    <TransparencyBubbles />
  </Box>
);

export default Transparency;
