import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import TechnicalInterviewersBubbles from '../../components/about/TechnicalInterviewersBubbles';
import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const technologies = [
  'Vue.js',
  'Python',
  'Golang',
  'PHP',
  'React.js',
  'Angular',
  'Ruby on Rails',
  'Scala',
  'SQL',
  'Node.js',
  'React Native',
  'Typescript',
  'Java',
  '.Net',
  'Adobe',
  'Html',
  'CSS',
  'Android (Java)',
  'Kotlin',
  'Unity',
  'C Sharp',
  'C++',
  'Gatsby',
  'PowerBI',
  'Tableau',
  'Jupyter',
  'Spark',
  'PostgreSQL',
  'Python for Data Science',
  'AWS',
  'Data Lakes',
  'Microsoft SQL Server',
  'GCP',
  'Oracle',
  'Heroku',
  'Docker',
  'CI/CD',
];

const pillColors = ['#EBF8FB', '#EAF2DF', '#FFEFE4', '#F4F8F9'];

const ColoredPill = styled.div`
  // color: ${({ theme }) => theme.colors.nextonBlue};
  // background: ${({ theme }) => theme.colors.nextonLightBlue};
  // background: ${pillColors[Math.floor(Math.random() * 4) + 1]};
  background: ${({ bg }) => bg};
  font-size: 14px;
  border-radius: 10px;
  padding: 4px 18px;
  margin-right: 6px;
  white-space: nowrap;
`;

const TechnicalInterviewers = () => (
  <Box py={['75px', '75px', '100px']} style={{ position: 'relative' }}>
    <Flex flexWrap='wrap' alignItems='flex-start'>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <Text fontSize={['45px', '70px']} color='nextonBlue' my='15px' as='h2' fontWeight='400'>
          Technical Interviewers
        </Text>
        <Text fontSize={['18px', '20px']} lineHeight='1.6' mb='50px'>
          Our team of technical interviewers is made up of experienced technical experts, making the interview a
          conversation between peers. They help us to quickly assess and match the technical talent with the right
          opportunities.
        </Text>
      </Box>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <WhiteRoundCard2 padding='30px 35px' maxWidth='560px'>
          <Flex flexWrap='wrap' justifyContent='space-between'>
            {technologies.map(pill => (
              <Flex minWidth={[1 / 3]} key={pill}>
                <Box my='8px' mx='4px'>
                  <ColoredPill bg={pillColors[Math.floor(Math.random() * 4)]}>{pill}</ColoredPill>
                </Box>
              </Flex>
            ))}
          </Flex>
        </WhiteRoundCard2>
      </Box>
    </Flex>
    <TechnicalInterviewersBubbles />
  </Box>
);

export default TechnicalInterviewers;
