import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Loadable from 'react-loadable';

// Fix for third-party modules that expect window to be defined
// https://www.gatsbyjs.com/docs/debugging-html-builds/#fixing-third-party-modules
// In this case the Tabs were frozen upon initial load
const LoadableTeamTabs = Loadable({
  loader: () => import('./TeamTabs'),
  loading() {
    return <></>;
  },
});

const Team = () => {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { nextonTeamDesktop, nextonTeamTablet, nextonTeamMobile } = useStaticQuery(graphql`
    {
      nextonTeamDesktop: file(relativePath: { eq: "images/about/nexton-team.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 1326, height: 517, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
      nextonTeamTablet: file(relativePath: { eq: "images/about/nexton-team.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 688, height: 268, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }

      nextonTeamMobile: file(relativePath: { eq: "images/about/nexton-team.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 388, height: 151, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  return (
    <Box py={['75px', '75px', '100px']} mb={['90px', '90px', '50px']}>
      <Flex flexWrap='wrap' alignItems='flex-start' px={['0px', '20px']}>
        <Text fontSize={['45px', '70px']} color='nextonBlue' my='15px' as='h2' fontWeight='400'>
          Meet the Team
        </Text>
        <Text fontSize={['20px', '22px']} lineHeight='1.6' mb='40px'>
          We live and breathe remote work. Our 100% distributed team is multicultural and multidisciplinary.
        </Text>
        <Box width='100%'>
          {windowWidth <= 420 ? (
            <GatsbyImage image={nextonTeamMobile.childImageSharp.gatsbyImageData} alt='Nexton Team' />
          ) : windowWidth <= 768 ? (
            <GatsbyImage image={nextonTeamTablet.childImageSharp.gatsbyImageData} alt='Nexton Team' />
          ) : (
            <GatsbyImage image={nextonTeamDesktop.childImageSharp.gatsbyImageData} alt='Nexton Team' />
          )}
        </Box>
      </Flex>
      <Flex flexWrap='wrap' alignItems='flex-start' mx='-20px' pt='40px' px={['0px', '20px']}>
        <Box width='100%' mb='-130px'>
          <LoadableTeamTabs />
        </Box>
      </Flex>
    </Box>
  );
};

export default Team;
