import React from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const StyledLink = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.nextonBlue};
  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: brightness(115%);
  }
`;

const ImpactInitiatives = () => {
  const { academy, scholarships } = useStaticQuery(graphql`
    {
      academy: file(relativePath: { eq: "images/about/academy.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        }
        name
      }
      scholarships: file(relativePath: { eq: "images/about/scholarships.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        }
        name
      }
    }
  `);

  return (
    <Box py={['75px', '75px', '100px']}>
      <Flex
        flexWrap='wrap'
        alignItems='flex-start'
        pt={['25px', '25px', '50px']}
        pb={['0px', '0px', '0px']}
        bg='nextonLightBlue'
        mx='-20px'
        px='20px'
        mb='50px'>
        <Box width='100%' px='20px'>
          <Text fontSize={['45px', '70px']} color='nextonBlue' mt='15px' as='h2' fontWeight='400'>
            Impact Initiatives
          </Text>
        </Box>
        <Box
          width={[1, 1, 1 / 2]}
          px={['0px', '20px']}
          mt={['50px', '60px']}
          mb='-50px'
          minHeight='720px'
          alignContent='flex-start'>
          <WhiteRoundCard2 padding='0px' maxWidth='560px'>
            <Box m={['-30px -30px 0px -30px', '0px']}>
              <GatsbyImage image={scholarships.childImageSharp.gatsbyImageData} alt='Scholarships' />
            </Box>
            <Box px={['0px', '40px']} pt={['10px', '30px']} pb={['0px', '40px']}>
              <Text fontSize={['30px', '32px']} color='nextonBlue' my='15px'>
                Nexton Scholarships
              </Text>
              <Text fontSize={['18px', '20px']} lineHeight='1.6'>
                English skills are fundamental for technical talent looking to expand their career beyond their borders.
                Nexton provides the opportunity for candidates to level up their english and take their careers to the
                next level. Nexton Scholarship is currently closed.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mt={['100px', '60px']} mb='-50px'>
          <WhiteRoundCard2 padding='0px' maxWidth='560px'>
            <Box m={['-30px -30px 0px -30px', '0px']}>
              <GatsbyImage image={academy.childImageSharp.gatsbyImageData} alt='Academy' />
            </Box>
            <Box px={['0px', '40px']} pt={['10px', '30px']} pb={['0px', '40px']}>
              <Text fontSize={['30px', '32px']} color='nextonBlue' my='15px'>
                Nexton Academy
              </Text>
              <Text fontSize={['18px', '20px']} lineHeight='1.6'>
                The program introduces the younger generation to the technical recruiting industry, showing them best
                practices and the way of success matching top tier talent of LATAM with amazing mission-driven US
                startups. <StyledLink to='/academy'>Enter the waitlist →</StyledLink>
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
      </Flex>
    </Box>
  );
};

export default ImpactInitiatives;
