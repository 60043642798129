import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';
import { useMediaQuery } from 'react-responsive';

import downArrow from '../../../assets/images/down-arrow.svg';

const AboutHero = () => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "images/about/hero" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 95, placeholder: NONE, layout: CONSTRAINED)
          }
          name
        }
      }
    }
  `);
  const heroImages = {};
  allFile.nodes.forEach(img => {
    heroImages[img.name] = { ...img.childImageSharp.gatsbyImageData };
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 641px)',
  });

  return (
    <Box className='body-layout'>
      <Flex flexDirection='column' alignItems='center' justifyContent='space-between'>
        <Flex flexWrap='wrap' justifyContent='center' alignItems='flex-start' pt='180px' color='white'>
          <Box width={[1, 1, 8 / 20, 9 / 20]} pl={['0px', '20px']} mt={['0px', '30px']} mb='50px'>
            <Fade duration={1000} delay={500}>
              <Text fontSize='30px' mb='20px'>
                About Nexton
              </Text>
              <Text
                fontSize={['46px', '49px', '49px', '76px']}
                fontWeight='300'
                lineHeight='1.11'
                letterSpacing='-1.5px'
                as='h1'>
                We connect{' '}
                <Text as='span' fontWeight='500'>
                  technical talent
                </Text>{' '}
                with purposeful{' '}
                <Text as='span' fontWeight='500'>
                  startups
                </Text>{' '}
                around the world
              </Text>
            </Fade>
          </Box>
          <Box
            width={[1, 1, 12 / 20, 11 / 20]}
            textAlign={['center', 'center', 'right']}
            mb={['0px', '-53px']}
            mr={['0px', '-20px']}>
            {isMobile && (
              <Flex flexWrap='wrap'>
                <Flex alignItems='center' my='20px'>
                  <Box width='50%'>
                    <GatsbyImage image={heroImages.ninja} alt='Ninja' />
                  </Box>
                  <Box width='50%'>
                    <Text>US Startups have a difficult time finding great engineering talent</Text>
                  </Box>
                </Flex>
                <Flex alignItems='center' my='20px'>
                  <Box width='50%'>
                    <Text>We have solid experience and deep knowledge about the Latin American Tech Talent</Text>
                  </Box>
                  <Box width='50%'>
                    <GatsbyImage image={heroImages.nexton} alt='Nexton' />
                  </Box>
                </Flex>
                <Flex alignItems='center' my='20px'>
                  <Box width='50%'>
                    <GatsbyImage image={heroImages.globe} alt='Globe' />
                  </Box>
                  <Box width='50%'>
                    <Text>Remote work opened a world of opportunities</Text>
                  </Box>
                </Flex>
                <Box my='40px'>
                  <GatsbyImage image={heroImages.unicornNoShadow} alt='Unicorn' />
                </Box>
              </Flex>
            )}
            {isDesktopOrLaptop && <GatsbyImage image={heroImages.weConnectGraphic} alt='We Connect Graphic' />}
          </Box>
        </Flex>

        <Box textAlign='center' pb='20px'>
          <Image src={downArrow} alt='Down Arrow Image' />
        </Box>
      </Flex>
    </Box>
  );
};

export default AboutHero;
