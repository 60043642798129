import React from 'react';
import { Box, Flex, Text } from 'rebass';

import CultureBubbles from '../../components/about/CultureBubbles';
import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const culture = [
  { phrase: 'Simplicity is better than complexity.', key: 'Time Optimization' },
  { phrase: 'We do as we say.', key: 'Transparency' },
  { phrase: 'Together we create magic.', key: 'Teamwork' },
  { phrase: 'We change lives.', key: 'Talent & Experience' },
  { phrase: 'Global yet local.', key: 'Remote' },
  { phrase: 'We challenge the status quo.', key: 'Value Creation' },
  { phrase: 'With humility comes wisdom.', key: 'Improvement Opportunities' },
];

const Culture = () => (
  <Box py={['75px', '75px', '100px']} style={{ position: 'relative' }}>
    <Flex flexWrap='wrap' alignItems='flex-start'>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <Text fontSize={['45px', '70px']} color='nextonBlue' my='15px' as='h2' fontWeight='400'>
          Culture
        </Text>
        <Text fontSize={['18px', '20px']} lineHeight='1.6' mb='50px'>
          We are a multidisciplinary team working around Latin America. Like puzzle pieces, our different backgrounds
          and experiences lead us to great teamwork and results. We are constantly learning from each other and opening
          ourselves to new ideas from our teammates.
          <br />
          <br />
          We believe in freedom to be ourselves and bring out our best. We encourage thoughtful, creative, and
          inspirational ideas. There is always room to grow.
        </Text>
      </Box>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <WhiteRoundCard2 padding='30px 35px' maxWidth='560px'>
          <Flex flexDirection='column'>
            {culture.map(item => (
              <Flex flexDirection='column' mx='4px' mt='8px' mb='25px' key={item.phrase}>
                <Text fontSize='28px' color='nextonBlue'>
                  {item.phrase}
                </Text>
                <Text fontSize='24px' fontWeight='300' fontStyle='italic' mt='6px'>
                  {item.key}
                </Text>
              </Flex>
            ))}
          </Flex>
        </WhiteRoundCard2>
      </Box>
    </Flex>
    <CultureBubbles />
  </Box>
);

export default Culture;
