import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const LightBlueBubble = styled(Box)`
  background-color: #f5fbfd;
  position: absolute;
  bottom: 166px;
  right: 22%;
  width: 381px;
  height: 381px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 73px;
    right: 47%;
  }
`;

const TechnicalInterviewersBubbles = () => (
  <Box>
    <LightBlueBubble />
  </Box>
);

export default TechnicalInterviewersBubbles;
